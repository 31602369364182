@import '../../assets/variables.scss';

$asideWidth: 218px;

.main-layout {
	display: flex;
	flex-direction: column;
  background-color: $greyColor4;
	@media #{$desktop} {
		height: 100vh;
	}
	@media #{$phone_tablet} {
		min-height: 100vh;
	}
	&_with-drawer {
		@media #{$tablet_desktop} {
			overflow: hidden;
		}
		@media #{$tablet} {
			height: 100vh;
		}
	}
	&_white-bg {
		background-color: $whiteColor;
	}
	&__header {
		z-index: 10;
		@media #{$desktop} {
			position: relative;
		}
		@media #{$phone_tablet} {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
		}
	}
	&__body {
		flex-grow: 1;
		flex-shrink: 1;
		@media #{$desktop} {
			margin-left: $asideWidth;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}
		@media #{$md_lg} {
			padding: 35px 40px;
		}
		@media #{$sm} {
			padding: 30px;
		}
		@media #{$tablet} {
			padding: 120px 30px 30px;
		}
		@media #{$phone} {
			padding: 90px 20px 20px;
		}
	}
	&__aside {
		position: fixed;
		top: 94px;
		left: 0;
		bottom: 0;
		width: $asideWidth;
		background-color: $whiteColor;
		box-shadow: 1px 3px 10px rgba($siteColor,0.1);
	}
	&__footer {
		@media #{$desktop} {
			margin-left: $asideWidth;
		}
	}
	&__drawer {
		@media #{$tablet_desktop} {
			position: fixed;
			z-index: 11;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($siteColor,0.4);
		}
		@media #{$phone} {
			flex-grow: 1;
			flex-shrink: 1;
			background-color: $whiteColor;
		}
		&-head {
			display: flex;
			margin-bottom: 32px;
			&-wrap {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}
		&-title {
			font-size: 20px;
			line-height: 1.2;
			font-weight: 600;
		}
		&-label {
			margin-top: 18px;
			font-size: 14px;
			line-height: 1.4;
			font-weight: 500;
			color: $greyColor1;
		}
		&-close {
			flex-shrink: 0;
			margin-left: 20px;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				.main-layout__drawer-close-path {
					fill: $redColor;
				}
			}
			&-icon {
				flex-shrink: 0;
				width: 14px;
				height: 14px;
			}
			&-path {
				transition: 0.2s fill ease-in-out;
			}
		}
		&-content {
			@media #{$tablet_desktop} {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: $whiteColor;
				overflow-x: hidden;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
			}
			@media #{$lg} {
				width: 38vw;
				max-width: 700px;
				padding: 60px 5vw 100px;
			}
			@media #{$sm_md} {
				width: 516px;
				padding: 60px 46px 100px;
			}
			@media #{$tablet} {
				width: 516px;
				padding: 40px 30px 100px;
			}
			@media #{$phone} {
				padding: 30px 20px 100px;
			}
			&::-webkit-scrollbar {
				width: 3px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba($siteColor,0.2);
			}
		}
		&-footer {
			position: fixed;
			z-index: 10;
			bottom: 0;
			right: 0;
			background-color: $whiteColor;
			box-shadow: 0 4px 34px rgba(0, 0, 0, 0.10);
			@media #{$lg} {
				width: 38vw;
				max-width: 700px;
				padding: 20px 5vw;
			}
			@media #{$sm_md} {
				width: 516px;
				padding: 20px 46px;
			}
			@media #{$tablet} {
				width: 516px;
				padding: 20px 30px;
			}
			@media #{$phone} {
				left: 0;
				padding: 20px;
			}
		}
		&-buttons {
			display: flex;
			margin: 0 -5px;
			&-item {
				flex-shrink: 0;
				width: 50%;
				padding: 0 5px;
				&:first-child:last-child {
					width: 100%;
				}
			}
		}
	}
}