@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.form-group {
	.form-field {
		position: relative;
	}
	.input-file-name {
		padding-right: 100px;
	}
	.show-password-button {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(-10px, -50%);
		line-height: 0;
		color: $greyColor;
		opacity: 0;
		visibility: hidden;
		transition: 0.2s color ease-in-out, 0.2s opacity ease-in-out;
		&.active {
			opacity: 1;
			visibility: visible;
		}
		&:hover {
		  color: $brandColor;
		}
		svg {
      stroke: currentColor;
		}
	}
	.remove-button {
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translate(-10px, -50%);
		line-height: 0;
	}
}