@import "../../assets/mixins";
@import "../../assets/variables";

.file-uploader-widget {
  position: relative;
  width: auto;
  .file-uploader-wrapper {
    font-size: 14px;
    font-weight: 600;
    color: $siteColor;
    border-radius: 8px;
    border: 1px dashed $greyColor2;
    transition: 0.2s border-color ease-in-out;
    &:hover {
      border-color: $brandColor;
    }
    &_hide {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
    }
  }
  .file-drop-area {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 25px;
    transition: 0.2s background-color ease-in-out;
    &.is-active {
      background-color: rgba($brandColor, 0.05);
    }
  }
  .file-drop-area-wrap {
    min-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-accept-label {
    margin-top: 5px;
    font-size: 13px;
    font-weight: normal;
    color: $greyColor1;
    text-align: center;
  }
  .choose-file-btn {
    flex-shrink: 0;
    color: $brandColor;
  }
  .file-message {
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    &:focus {
      outline: none;
    }
  }
  progress {
    min-width: 250px;
    transition: all 500ms;
    width:100%;
    &::-webkit-progress-value {
      transition: width 1s;
      }
      &::-moz-progress-bar {
        transition: padding-bottom 1s;
        padding-bottom: var(--value);
        transform-origin: 0 0;
        transform: rotate(-90deg) translateX(-15px);
        padding-left: 15px;
      }
  }
  .file-progress {
    margin-top: 15px;
    &-bar {
      position: relative;
      height: 4px;
      border-radius: 2px;
      overflow: hidden;
      background-color: $greyColor4;
    }
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $brandColor;
      border-radius: 2px;
    }
    &-value {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.file-uploader-info {
  display: flex;
  align-items: center;
  &-icon {
    flex-shrink: 0;
    margin-right: 16px;
    width: 58px;
    height: 58px;
    border-radius: 4px;
    background: $greyColor4;
    display: flex;
    justify-content: center;
    align-items: center;
    &-i {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }
  &-content {
    flex-shrink: 0;
    width: calc(100% - 134px)
  }
  &-name {
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &-size {
    font-size: 13px;
    color: $greyColor1;
  }
  &-delete {
    flex-shrink: 0;
    margin-left: 16px;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 4px;
    background: $greyColor4;
    border: 0 none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s background ease-in-out;
    &:hover {
      background: rgba($redColor,0.2);
    }
    &-icon {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
  }
}