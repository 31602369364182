@import '../../assets/variables.scss';

.page-sidebar {
	display: flex;
	flex-direction: column;
	@media #{$desktop} {
		height: 100%;
	}
	@media #{$phone_tablet} {
		min-height: calc(100vh - 150px);
	}
	&__head {
		flex-shrink: 0;
		position: relative;
		z-index: 2;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		box-shadow: 0 4px 7px rgba($siteColor,0.05);
	}
	&__body {
		flex-grow: 1;
		flex-shrink: 1;
	}
	&__wrap {
		@media #{$phone} {
			padding: 40px 20px 20px;
		}
	}
	&__menu {
		margin: 0;
		padding: 0;
		list-style: none;
		&-link {
			display: flex;
			align-items: center;
			padding: 19px 16px;
			font-size: 15px;
			font-weight: 700;
			color: $black1;
			text-decoration: none;
			border-left: 3px solid transparent;
			transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out, 0.2s background-color ease-in-out;
			&.active {
				background-color: rgba($brandColor,0.05);
				border-color: $brandColor;
			}
			&:hover {
				color: $brandColor;
				.page-sidebar__menu-icon path {
					fill: $brandColor;
				}
			}
		}
		&-icon {
			flex-shrink: 0;
			margin-right: 12px;
			width: 20px;
			height: 20px;
			path {
				transition: 0.2s fill ease-in-out;
			}
		}
	}
	&__footer {
		flex-shrink: 0;
		margin-top: auto;
		padding: 14px 16px;
	}
	&__logout {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		border: 0 none;
		outline: 0 none;
		background: none;
		color: $redColor2;
		cursor: pointer;
		transition: 0.2s color ease-in-out;
		&:hover {
			color: $brandColor;
			.page-sidebar__logout-icon path {
				fill: $brandColor;
			}
		}
		&-icon {
			flex-shrink: 0;
			margin-right: 12px;
			width: 20px;
			height: 20px;
			path {
				fill: $redColor2;
				transition: 0.2s fill ease-in-out;
			}
		}
		&-label {
			font-size: 15px;
			line-height: 1.2;
			font-weight: 700;
		}
	}
	&__notice {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    color: $siteColor;
    transition: 0.2s color ease-in-out;
    &:hover {
      color: $brandColor;
    }
    &-icon {
      flex-shrink: 0;
      margin-right: 8px;
      position: relative;
      &.active {
        &:before {
          opacity: 1;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -3px;
        width: 5px;
        height: 5px;
        background-color: $redColor2;
        border-radius: 50%;
        opacity: 0;
      }
      &-i {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }
	&__auth {
    display: flex;
    align-items: center;
    color: $siteColor;
		@media #{$desktop} {
			margin-bottom: 20px;
			padding: 20px;
		}
    &-label {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media #{$md_lg} {
        max-width: 250px;
      }
      @media #{$sm} {
        max-width: 200px;
      }
    }
    &-icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $brandColor;
      border-radius: 50%;
      line-height: 1.2;
      font-weight: 600;
      color: $whiteColor;
      text-transform: uppercase;
			@media #{$desktop} {
				margin-right: 12px;
				width: 40px;
				height: 40px;
				font-size: 15px;
			}
			@media #{$phone_tablet} {
				margin-right: 7px;
				width: 32px;
				height: 32px;
				font-size: 12px;
			}
    }
  }
}
