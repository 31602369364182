@import '../../../../assets/variables.scss';
@import '../../../../assets/mixins.scss';

.payment-chooser {
  &__wrap {
    @media (min-width: 1160px) {
      display: flex;
      margin: 0 -6px;
      padding: 4px 0;
    }
    &_col-3 {
      .payment-chooser {
        &__col {
          @media (min-width: 1160px) {
            width: 33.333333%;
          }
        }
        &__item {
          &.selected {
            @media (min-width: 1160px) {
              margin: -16px 0;
              padding-top: 47px;
              padding-bottom: 47px;
            }
          }
        }
      }
    }
    &_col-2 {
      @media #{$tablet_desktop} {
        display: flex;
        margin: 0 -6px;
        padding: 4px 0;
      }
      .payment-chooser {
        &__col {
          @media #{$tablet_desktop} {
            width: 50%;
            flex-shrink: 0;
            display: flex;
            padding: 0 6px;
            margin: 0;
          }
        }
      }
    }
    &_col-1 {
      @media (min-width: 1160px) {
        width: 33.333333%;
      }
    }
  }
  &__col {
    @media (min-width: 1160px) {
      flex-shrink: 0;
      display: flex;
      width: 33.333333%;
      padding: 0 6px;
    }
    @media (max-width: 1159px) {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__item {
    flex-shrink: 0;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: $whiteColor;
    border: 1px solid $greyColor9;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s border-color ease-in-out;
    @media #{$tablet_desktop} {
      padding: 32px 24px;
    }
    @media #{$phone} {
      padding: 16px;
    }
    &.active {
      border-color: $brandColor;
      .payment-chooser__item-badge {
        color: $whiteColor;
        background-color: $brandColor;
      }
    }
    &.selected {
      border-width: 2px;
      @media #{$tablet_desktop} {
        padding: 31px 23px;
      }
      @media #{$phone} {
        padding: 40px 15px 15px;
      }
    }
    &-image {
      img {
        width: 40px;
      }
      margin-bottom: 10px;
    }
    &-badge {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 6px;
      font-size: 10px;
      line-height: 1.3;
      font-weight: bold;
      text-align: center;
      background-color: $greyColor9;
      border-radius: 2px 2px 0 0;
      transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out;
    }
    &-most-popular {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 5px 8px;
      margin-left: 5px;
      gap: 8px;

      width: 80px;
      height: 23px;

      background: #F6F9FF;
      border-radius: 48px;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;

      color: #334983;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      @media #{$tablet_desktop} {
        margin-bottom: 10px;
      }
      @media #{$phone} {
        margin-bottom: 5px;
      }
    }
    &-footer {
      margin-top: auto;
      flex-shrink: 0;
    }
    &-price {
      margin-bottom: 16px;
      font-size: 24px;
      font-weight: bold;
      color: $brandColor;
    }
    &-button {
      &_disabled {
        pointer-events: none;
      }
    }
  }
  &__options {
    margin: 0 0 16px;
    padding: 0;
    list-style: none;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 500;
    color: $greyColor1;
    &-item {
      span {
        display: block;
        color: $siteColor;
        font-weight: bold;
      }
    }
  }
}
