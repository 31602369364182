@import "../../assets/variables.scss";

.cookies-popup {
	position: fixed;
	z-index: 999;
	overflow: hidden;
	background-color: $whiteColor;
	box-shadow: 0 4px 52px rgba($siteColor, 0.1);
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	@media #{$desktop} {
		right: 40px;
		bottom: 40px;
	}
	@media #{$tablet} {
		right: 30px;
		bottom: 30px;
	}
	@media #{$tablet_desktop} {
		width: 548px;
		max-height: calc(100vh - 80px);
		padding: 32px;
		border-radius: 12px;
	}
	@media #{$phone} {
		right: 20px;
		bottom: 20px;
		left: 20px;
		max-height: calc(100vh - 40px);
		padding: 20px;
		border-radius: 8px;
	}
	&::-webkit-scrollbar {
		width: 3px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba($siteColor,0.2)
	}
	&__title {
		margin-bottom: 13px;
		line-height: 1.2;
		font-weight: bold;
		@media #{$tablet_desktop} {
			font-size: 20px;
		}
		@media #{$phone} {
			font-size: 18px;
		}
	}
	&__text {
		line-height: 1.4;
		font-weight: 500;
		@media #{$tablet_desktop} {
			font-size: 14px;
		}
		@media #{$phone} {
			font-size: 12px;
		}
	}
	&__buttons {
		margin-top: 24px;
		display: flex;
	}
	&__button {
		flex-shrink: 0;
		margin-right: 20px;
		&:last-child {
			margin-right: 0;
		}
		&.btn-default {
			@media #{$phone} {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
	&__settings {
		&-title {
			margin-bottom: 13px;
			font-size: 20px;
			line-height: 1.2;
			font-weight: bold;
		}
		&-text {
			font-size: 12px;
			line-height: 1.4;
		}
		&-list {
			margin-top: 8px;
		}
		&-item {
			display: block;
			position: relative;
			padding: 14px 0;
			border-bottom: 1px solid rgba($siteColor, 0.1);
			user-select: none;
			&:last-child {
				border-bottom: 0 none;
			}
			&-title {
				margin: 0 60px 12px 0;
				font-size: 15px;
				line-height: 1.2;
				font-weight: 500;
			}
			&-text {
				font-size: 12px;
				line-height: 1.4;
				color: rgba($siteColor, 0.5);
			}
		}
		&-icon {
			position: absolute;
			top: 14px;
			right: 0;
			width: 34px;
			height: 18px;
			border-radius: 9px;
			background-color: $greyColor3;
			cursor: pointer;
			transition: 0.2s background-color ease-in-out;
			&:before {
				content: '';
				position: absolute;
				top: 2px;
				left: 2px;
				width: 14px;
				height: 14px;
				background-color: $whiteColor;
				border-radius: 50%;
				transition: 0.2s transform ease-in-out;
			}
			&.active {
				background-color: $greyColor;
				pointer-events: none;
				cursor: default;
				&:before {
					transform: translateX(16px);
				}
			}
		}
		&-checkbox {
			position: absolute;
			z-index: 2;
			top: 14px;
			right: 0;
			width: 34px;
			height: 18px;
			opacity: 0;
			cursor: pointer;
			&:checked + .cookies-popup__settings-icon {
				background-color: $brandColor;
				&:before {
					transform: translateX(16px);
				}
			}
		}
	}
}