@import '../../assets/variables.scss';

.page-notice {
	margin-bottom: 40px;
	display: flex;
	border-radius: 12px;
	border-width: 1px;
	border-style: solid;
	font-size: 14px;
	line-height: 1.428;
	@media #{$tablet_desktop} {
		padding: 16px 24px;
	}
	@media #{$phone} {
		padding: 16px;
	}
	&_warning {
		border-color: #f2994a;
		color: #b54708;
		background-color: #fff9f6;
	}
	&_highlight {
		border-color: #f2994a;
		color: #b54708;
		background-color: #fff9f6;
	}
	&_error {
		border-color: #EB5757;
		background-color: #FFF4F4;
	}
	&_success {
		border-color: #3EC99F;
		background-color: #EFFFFB;
	}
	&__link {
		margin-top: 20px;
		a:link {
			color: #b54708;
		}
		
		/* Visited link */
		a:visited {
			color: #b54708;
		}
		
		/* Mouse over link */
		a:hover {
			color: red;
		}
	}
	&__icon {
		flex-shrink: 0;
		margin-right: 10px;
		&-i {
			display: block;
			width: 36px;
			height: auto;
		}
	}
	&__content {
		flex-grow: 1;
		flex-shrink: 1;
		padding: 5px 0;
	}
	&__title {
		margin-bottom: 5px;
		font-weight: 600;
	}
}