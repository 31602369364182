@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.order-info {
  flex-shrink: 0;
  margin-top: auto;
  display: flex;
  background-color: rgba($brandColor,0.05);
  border: 1px solid rgba($brandColor,0.1);
  border-radius: 4px;
  @media #{$tablet_desktop} {
    padding: 32px;
  }
  @media #{$phone} {
    padding: 16px;
  }
  &_inverted {
    flex-direction: row-reverse;
    .order-info {
      &__image {
        @media #{$tablet_desktop} {
          margin: 0 0 0 20px;
          width: 130px;
        }
        @media #{$phone} {
          margin: 0 0 0 15px;
        }
      }
    }
  }
  &__image {
    flex-shrink: 0;
    @media #{$tablet_desktop} {
      margin: 0 15px 0 -20px;
      width: 150px;
    }
    @media #{$phone} {
      margin: 0 15px 0 -10px;
      width: 100px;
    }
    &-i {
      display: block;
      width: 100%;
      height: auto;
    }
    .animation-image {
      width: 100%;
    }
  }
  &__content {
    flex-grow: 1;
    flex-shrink: 1;
  }
  &__title {
    margin-bottom: 8px;
    line-height: 1.2;
    font-weight: bold;
    color: $brandColor;
    @media #{$tablet_desktop} {
      font-size: 18px;
    }
    @media #{$phone} {
      font-size: 16px;
    }
  }
  &__text {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 500;
    color: $greyColor1;
    a {
      color: $brandColor;
      font-weight: bold;
      text-decoration: none;
      transition: 0.2s color ease-in-out;
      &:hover {
        color: $siteColor;
      }
    }
  }
  &__footer {
    margin-top: 24px;
  }
  &__button {
    min-width: 160px;
  }
}
