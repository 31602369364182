@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.source-code-info {
	&__widget {
		margin-bottom: 40px;
	}
	.rc-collapse {
		border: 0 none;
		background-color: transparent;
	}
	.rc-collapse-item {
		border: 0 none !important;
		margin-bottom: 12px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.rc-collapse-header {
		display: flex;
		flex-direction: row-reverse;
		padding: 0 !important;
		color: $siteColor !important;
		background-color: $greyColor4;
		border-radius: 4px;
	}
	.rc-collapse-item-active .rc-collapse-header .arrow {
		transform: rotate(180deg);
	}
	.rc-collapse-expand-icon {
		flex-shrink: 0;
		margin: 0 20px;
		.arrow {
			top: 0 !important;
			margin: 0 !important;
			border-style: solid !important;
			border-width: 5px 4px 0 !important;
			border-color: $siteColor transparent transparent transparent !important;
		}
	}
	.rc-collapse-header-text {
		flex-grow: 1;
		flex-shrink: 1;
		.fields-group-title {
			margin: 0;
		}
	}
	.rc-collapse-content {
		padding: 0;
	}
}