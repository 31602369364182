@import '../../assets/variables.scss';

.enable-mfa-success {
	padding: 40px 0;
	&__image {
		margin: 0 auto 40px;
		width: 100%;
		max-width: 300px;
	}
	&__description {
		font-size: 15px;
		line-height: 1.4;
		font-weight: bold;
		color: $black1;
	}
}

.disable-mfa-success {
	padding: 40px 0;
	&__image {
		margin: 0 auto 40px;
		width: 100%;
		max-width: 300px;
	}
	&__title {
		font-size: 15px;
		line-height: 1.4;
		font-weight: bold;
		color: $black1;
	}
}

.mfa-disable {
	&__image {
		margin: 0 auto 40px;
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: 280px;
	}
	&__title {
		margin-bottom: 20px;
		font-size: 24px;
		line-height: 1.4;
		font-weight: 600;
	}
	&__text {
		margin: 0 0 16px;
		font-size: 15px;
		line-height: 1.4;
		font-weight: bold;
		color: $black1;
	}
}