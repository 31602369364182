@import '../../assets/variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $whiteColor;
  box-shadow: 0 4px 7px rgba($siteColor, 0.05);
  @media #{$desktop} {
    padding: 21px 40px;
  }
  @media #{$tablet} {
    padding: 20px 30px;
  }
  @media #{$tablet_desktop} {
    position: relative;
    flex-shrink: 0;  
  }
  @media #{$phone} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 150;
    padding: 20px;
  }
  &_simple {
    box-shadow: none;
    @media #{$desktop} {
      background-color: transparent;
    }
    @media #{$desktop} {
      padding-left: 10vw;
      padding-right: 10vw;
    }
    @media #{$tablet} {
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }
  &__aside {
    display: flex;
    align-items: center;
  }
  &__logo {
    flex-shrink: 0;
    text-decoration: none;
    @media #{$tablet_desktop} {
      width: 249px;
      height: 26px;
    }
    @media #{$phone} {
      width: 220px;
      height: 23px;
    }
    &-image {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__notice {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    color: $siteColor;
    transition: 0.2s color ease-in-out;
    &:hover {
      color: $brandColor;
    }
    &-icon {
      flex-shrink: 0;
      margin-right: 8px;
      position: relative;
      &.active {
        &:before {
          opacity: 1;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: -3px;
        width: 5px;
        height: 5px;
        background-color: $redColor2;
        border-radius: 50%;
        opacity: 0;
      }
      &-i {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
    &-label {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
    }
  }
  &__auth {
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: $siteColor;
    text-decoration: none;
    &-label {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media #{$md_lg} {
        max-width: 250px;
      }
      @media #{$sm} {
        max-width: 200px;
      }
    }
    &-icon {
      flex-shrink: 0;
      margin-right: 7px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $brandColor;
      border-radius: 50%;
      font-size: 12px;
      line-height: 1.2;
      font-weight: 600;
      color: $whiteColor;
      text-transform: uppercase;
    }
  }
  &__start-button {
    flex-shrink: 0;
    @media #{$tablet_desktop} {
      min-width: 160px;
      margin-left: 20px;
    }
    @media #{$phone} {
      margin-left: 10px;
      font-size: 13px;
    }
  }
  &__toggle {
    position: relative;
    margin-right: -13px;
    width: 50px;
    height: 50px;
    border: 0 none;
    user-select: none;
    cursor: pointer;
    outline: 0 none;
    background: none;
    @media #{$tablet} {
      margin-left: 50px;
    }
    @media #{$phone} {
      margin-top: -10px;
      margin-bottom: -10px;
    }
    &_active {
      &:before {
        width: 24px !important;
        margin: -1px 0 0 -12px !important;
        transform: rotate(-45deg);
        transition: 0.2s transform 0.2s ease-in-out, 0.2s margin ease-in-out, 0.2s width ease-in-out;
      }
      .header__toggle-item {
        transform: rotate(45deg);
        margin-top: -1px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 2px;
      background-color: $siteColor;
      margin: 3px 0 0 -2px;
      transition: 0.2s transform ease-in-out, 0.2s margin ease-in-out, 0.2s width ease-in-out;
    }
    &-item {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 2px;
      margin: -5px 0 5px -12px;
      background-color: $siteColor;
      transition: 0.2s transform ease-in-out, 0.2s margin-top ease-in-out;
    }
  }
  &__menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 40px 20px;
    background-color: rgba($siteColor,0.3);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    @media #{$tablet} {
      top: 90px;
    }
    @media #{$phone} {
      top: 70px;
    }
    &-content {
      margin: 0 auto;
      min-height: 100%;
      background-color: $whiteColor;
      border-radius: 14px;
      overflow: hidden;
      @media #{$tablet} {
        width: 450px;
      }
      @media #{$phone} {
        max-width: 450px;
      }
    }
  }
}
