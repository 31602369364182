@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.agreement {
	&__content {
		// max-height: 450px;
		padding-right: 10px;
		overflow-y: auto;
		overflow-x: hidden;
		margin-bottom: 22px;
		-webkit-overflow-scrolling: touch;
		&::-webkit-scrollbar {
			width: 3px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgba($siteColor,0.2);
		}
	}
	&__text {
		margin-top: 0;
		@include body();
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__list {
		margin: 0 0 22px;
		padding: 0;
		list-style: none;
		@include body();
		li {
			position: relative;
			padding-left: 20px;
			margin-bottom: 3px;
			&:before {
				content: "";
				position: absolute;
				top: 6px;
				left: 5px;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: $siteColor;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}