@import './normalize.scss';
@import './buttons.scss';
@import './forms.scss';
@import './variables.scss';
@import './tabs.scss';

* {
	box-sizing: border-box;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	font-variant-ligatures: none;
	-webkit-font-variant-ligatures: none;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

body {
	position: relative;
	display: flex;
	flex-direction: column;
	font-family: $mainFont;
	font-size: 16px;
	line-height: 1.5;
	color: $siteColor;
	background-color: $whiteColor;
}

img {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}