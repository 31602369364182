@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.faq {
  &__inner {
    display: flex;
    gap: 20px;
    @media #{$phone_tablet} {
      flex-direction: column;
    }
  }
  &__content {
    flex-shrink: 1;
    flex-grow: 1;
  }
  &__top {
    margin-bottom: 25px;
    &-title {
      font-size: 24px;
      font-weight: 700;
    }
  }
  &__aside {
    flex-shrink: 0;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $whiteColor;
    border-radius: 8px;
    flex-shrink: 0;
    @media #{$md_lg} {
      padding: 52px 130px;
    }
    @media #{$sm} {
      padding: 30px 50px;
    }
    @media #{$tablet} {
      padding: 30px 50px;
    }
    @media #{$phone} {
      padding: 20px;
    }
  }
  &__image {
    @media #{$tablet_desktop} {
      margin-bottom: 25px;
      width: 308px;
      height: 209px;
    }
    @media #{$phone} {
      margin-bottom: 10px;
    }
  }
  &__title {
    margin-bottom: 8px;
    font-weight: 600;
    text-align: center;
    @media #{$tablet_desktop} {
      font-size: 24px;
    }
    @media #{$phone} {
      font-size: 22px;
    }
  }
  &__label {
    margin-bottom: 25px;
    font-size: 14px;
    color: $greyColor7;
    font-weight: 500;
    text-align: center;
    &_email {
      display: block;
      color: $brandColor;
      text-decoration: none;
      transition: 0.2s color ease-in-out;
      &:hover {
        color: $siteColor;
      }
    }
  }
  &__button {
    @media #{$phone} {
      width: 100%;
    }
  }
  &__list {
    &-item {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-title {
      margin-bottom: 25px;
      font-size: 15px;
      font-weight: bold;
    }
    &-text {
      font-size: 14px;
      line-height: 1.428;
      font-weight: 500;
      color: $greyColor7;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      p {
        margin: 0 0 15px;
        &:last-child {
          margin: 0;
        }
      }
      strong {
        color: $siteColor;
      }
      a {
        color: $brandColor;
        transition: 0.2s color ease-in-out;
        &:hover {
          color: $siteColor;
        }
      }
      code {
        display: inline-block;
        vertical-align: top;
        margin: 5px 0;
        padding: 10px;
        color: $siteColor;
        background-color: rgba($brandColor, 0.1);
        border-radius: 5px;
      }
      ul {
        margin: 0 0 20px;
        padding: 0;
        list-style: none;
        li {
          margin-bottom: 10px;
          position: relative;
          padding-left: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          &:before {
            content: "";
            position: absolute;
            left: 7px;
            top: 7px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $greyColor7;
          }
        }
      }
    }
  }
  .rc-collapse {
    border: none;
    .rc-collapse-expand-icon {
      display: none;
    }
    .rc-collapse-item {
      margin-bottom: 4px;
      border: none;
      .rc-collapse-header {
        position: relative;
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 700;
        color: $siteColor;
        border-radius: 8px;
        background-color: $whiteColor;
        user-select: none;
        @media #{$tablet_desktop} {
          padding: 24px 74px 24px 32px;
        }
        @media #{$phone} {
          padding: 24px 54px 24px 20px;
        }
        &:before,
        &:after {
          position: absolute;
          content:  "";
          width: 14px;
          height: 2px;
          background-color: $siteColor;
          transition: 0.2s transform ease-in-out;
          @media #{$tablet_desktop} {
            top: calc(50% + 1px);
            right: 34px;
          }
          @media #{$phone} {
            top: calc(50% - 1px);
            right: 26px;
          }
        }
        &:after {
          transform: rotate(90deg);
        }
      }
      &.rc-collapse-item-active {
        .rc-collapse-header:after {
          transform: rotate(0deg);
        }
      }
    }
    .rc-collapse-content {
      border-radius: 8px;
      @media #{$tablet_desktop} {
        padding: 0 32px;
      }
      @media #{$phone} {
        padding: 0 20px;
      }
    }
  }
}
