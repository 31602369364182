@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.mfa {
  width: 100%;
  &__title {
    margin: 0 0 8px;
    line-height: 1.2;
    font-weight: 600;
    @media #{$desktop} {
			font-size: 40px;
		}
		@media #{$tablet} {
			font-size: 34px;
		}
		@media #{$phone} {
			font-size: 24px;
    }
    &_bottom-offset {
      margin: 0 0 40px;
    }
  }
  &__description {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    color: $black1;
  }
  &__step-text {
    margin: 0 0 16px;
    font-size: 15px;
    line-height: 1.4;
    font-weight: bold;
    color: $black1;
    a {
      color: $brandColor;
      &:hover {
        color: $black1;
      }
    }
  }
  &__secret {
    &-label {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 1.4;
      font-weight: 600;
    }
    &-value {
      font-size: 14px;
      line-height: 1.4;
      font-weight: 500;
      color: $greyColor1;
      word-wrap: break-word;
      max-width: 100%;
    }
  }
  &__qr-code-image {
    width: 170px;
    height: auto;
  }
  .fields-group {
    margin-bottom: 32px;
  }
}