@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.service-popup {
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($siteColor,0.4);
	&__content {
		background-color: $whiteColor;
		border-radius: 8px;
		@media #{$phone} {
			padding: 40px 30px;
			width: 90%;
		}
		@media #{$tablet_desktop} {
			padding: 52px 32px;
			width: 488px;
		}
	}
	&__title {
		font-size: 20px;
		font-weight: 600;
		text-align: center;
	}
	&__subtitle {
		margin-top: 8px;
		font-size: 14px;
		font-weight: 500;
		color: $greyColor1;
		text-align: center;
	}
	&__buttons {
		display: flex;
		gap: 8px;
		margin-top: 30px;
		@media #{$tablet_desktop} {
			padding: 0 42px;
		}
		@media #{$phone} {
			flex-direction: column;
		}
	}
}
