.alert-message {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  svg {
    margin-right: 6px;
  }

  &.success {
    span {
      color: #259D22;
    }
  }

  &.error {
    span {
      color: #E85B5B;
    }
  }

  &.neutral {
    span {
        color: #111111;
    }
  }
}