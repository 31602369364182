@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.order-payment-status {
    &__title {
        margin: 0;
        @include h2();
    }

    &__text {
        margin: 0;

        @include body();
    }
}