@import '../../assets/variables.scss';

.animation-image {
	display: flex;
	justify-content: center;
	pointer-events: none;
	&-el {
		width: 100%;
	}
	&-i {
		flex-shrink: 0;
	}
}
