@import './variables.scss';

@mixin h1 {
  line-height: 1.2;
  font-weight: 800;
  @media #{$desktop} {
    font-size: 48px;
  }
  @media #{$tablet} {
    font-size: 38px;
  }
  @media #{$phone} {
    font-size: 24px;
  }
}

@mixin h2 {
  font-weight: 800;
  line-height: 1.2;
  @media #{$tablet_desktop} {
    font-size: 38px;
  }
  @media #{$phone} {
    font-size: 24px;
  }
}

@mixin h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

@mixin h5 {
  font-weight: 700;
  line-height: 1.2;
  @media #{$tablet_desktop} {
    font-size: 18px;
  }
  @media #{$phone} {
    font-size: 16px;
  }
}

@mixin h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
}

@mixin body {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
}

@mixin caption {
  font-size: 15px;
  line-height: 1.2;
  font-weight: 600;
}

@mixin caption-sm {
  font-size: 14px;
  line-height: 1.1;
  font-weight: 600;
}

@mixin btn-sm {
  font-size: 13px;
  line-height: 1.2;
  font-weight: 500;
}

@mixin label-lg {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
}

@mixin label {
  font-size: 12px;
  line-height: 1.4;
  font-weight: 500;
}

@mixin label-sm {
  font-size: 10px;
  line-height: 1.4;
  font-weight: 400;
}