@import '../../assets/variables.scss';


.btn {
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    border-top-color: $whiteColor;
    opacity: 1;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    width: 16px;
    height: 16px;
    transition: opacity 250ms;
    animation: rotate-spinner 1s linear;
    animation-iteration-count: infinite;
    transition-delay: 250ms;
  }
  &.disabled {
    color: $greyColor1;
    pointer-events: none;
    background: $greyColor9;
  }
  &.loading {
    pointer-events: none;
    background: $greyColor;
    .btn__inner-content {
      opacity: 0;
    }
  }
  &__inner-content {
    display: flex;
    align-items: center;
  }
  &__icon {
    flex-shrink: 0;
    margin: -1px 8px -1px 0;
    width: 20px;
    height: 20px;
  }
}

@keyframes rotate-spinner {
  100% {
    transform: rotate(360deg);
  }
}