@import '../../assets/variables.scss';

.project-tiles {
	&__title {
		margin: 0 0 22px;
		font-size: 20px;
		line-height: 1.2;
		font-weight: 600;
	}
	&__list {
		@media #{$tablet_desktop} {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px;
			overflow: visible !important;
		}
		&-item {
			@media #{$tablet_desktop} {
				flex-shrink: 0;
				width: 50%;
				padding: 0 10px 20px;
				display: flex;
			}
			@media #{$phone} {
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&__intro {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 40px 0;
		&-image {
			flex-shrink: 0;
			margin-bottom: 8px;
			@media #{$tablet_desktop} {
				width: 380px;
				margin-left: -30px;
			}
			@media #{$phone} {
				max-width: 380px;
				width: 100%;
			}
		}
		&-title {
			margin: 0 0 15px;
			font-size: 32px;
			font-weight: 600;
		}
		&-text {
			font-size: 14px;
			line-height: 1.4;
			font-weight: 500;
			color: $greyColor1;
		}
		&-footer {
			margin-top: 32px;
		}
	}
	&__loading {
		flex-shrink: 0;
		width: 100%;
		display: flex;
		justify-content: center;
	}
}
