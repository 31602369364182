@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.mfa-success {
  &__title {
    margin: 0 0 8px;
    line-height: 1.2;
    font-weight: 600;
    @media #{$desktop} {
			font-size: 40px;
		}
		@media #{$tablet} {
			font-size: 34px;
		}
		@media #{$phone} {
			font-size: 24px;
    }
  }
  &__description {
    margin: 0 0 40px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    color: $black1;
  }
}