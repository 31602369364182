@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.form-group {
	.form-static-wrap {
		padding-top: 5px;
		display: flex;
		align-items: flex-start;
	}
	.form-static-toggle {
		flex-shrink: 0;
		margin-left: 10px;
		font-size: 12px;
		line-height: 1.2;
		font-weight: 500;
		color: $brandColor;
		padding: 3px 0 0;
		border-width: 0 0 1px;
		border-style: dotted;
		border-color: $brandColor;
		outline: 0 none;
		background: transparent;
		user-select: none;
		cursor: pointer;
	}
	.form-static-input {
		font-size: 14px;
		line-height: 1.4;
		font-weight: 500;
		color: $siteColor;
	}
	.form-control {
		&:placeholder-shown {
			& + .form-placeholder {
				opacity: 0;
			}
		}
	}
	.form-field {
		position: relative;
	}
	.form-label {
		display: block;
		margin-bottom: 5px;
		@include label();
		color: $greyColor1;
		sup {
			top: 0;
			font-size: 100%;
			color: $redColor2;
		}
		&_bottom-offset {
			margin-bottom: 12px;
		}
	}
	.tooltip-container {
		padding: 24px;
		background: $whiteColor;
		border-radius: 7px;
		word-break: break-word;
		border: 0 none;
		box-shadow: 0 4px 24px rgba(0, 0, 0, 0.10);
		@media #{$tablet_desktop} {
			max-width: 400px;
		}
		@media #{$phone} {
			max-width: 220px;
		}
	}
	.tooltip-arrow {
		display: none;
	}
	.control-top-labels {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 5px;
		.form-label {
			margin-bottom: 0;
		}
	}
	.tooltip-title {
		margin-top: 5px;
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		user-select: none;
		cursor: pointer;
		color: $greyColor1;
		font-size: 12px;
		line-height: 1.16;
		font-weight: 600;
		transition: 0.2s color ease-in-out;
		&:hover {
			color: $siteColor;
			.icon-wrapper path {
				fill: $siteColor;
			}
		}
		.icon-wrapper {
			margin-right: 5px;
			svg {
				display: block;
				width: 16px;
				height: 16px;
			}
			path {
				fill: $greyColor1;
				transition: 0.2s fill ease-in-out;
			}
		}
	}
	.form-placeholder {
		position: absolute;
		top: -4px;
		left: 12px;
		padding: 0 3px;
		font-size: 10px;
		line-height: 1;
		background-color: $whiteColor;
		transition: 0.2s opacity ease-in-out;
	}
	// OTP input
	.form-control-otp {
		flex-shrink: 1;
		flex-grow: 1;
		width: 40px !important;
		padding-left: 0;
		padding-right: 0;
		text-align: center;
	}
	.otp-input-separator {
		flex-shrink: 0;
		display: inline-block;
		vertical-align: top;
		width: 8px;
	}
}

.react-datepicker {
	font-family: $mainFont !important;
	border-radius: 8px !important;
	box-shadow: 0 4px 14px rgba($siteColor, 0.1);
	border: 0 none !important;
	&__triangle {
		display: none !important;
	}
	&__navigation--next,
	&__navigation--previous {
		top: 1.1rem !important;
		background-color: $greyColor4 !important;
		border-radius: 4px;
		&:hover {
			background-color: $brandColor !important;
			.react-datepicker__navigation-icon:before {
				border-color: $whiteColor !important;
			}
		}
	}
	&__navigation--next {
		right: 1.5rem !important;
	}
	&__navigation--previous {
		left: 1.5rem !important;
	}
	&__navigation-icon {
		&:before {
			top: 8px !important;
			border-width: 2px 2px 0 0 !important;
			border-color: $siteColor !important;
		}
		&--next:before {
			left: -4px !important;
		}
		&--previous:before {
			right: -4px !important;
		}
	}
	&-wrapper {
		width: 100%;
	}
	&__header {
		padding: 1.3rem 1.3rem 0 !important;
		border-bottom: 0 none !important;
		background-color: transparent !important;
	}
	&__month {
		margin: 1.3rem !important;
	}
	&__current-month {
		font-weight: 600 !important;
	}
	&__day-name,
	&__day,
	&__time-name {
		font-weight: 600;
		width: 2.5rem !important;
		line-height: 2.5rem !important;
		border-radius: 50% !important;
	}
	&__day-names {
		margin-top: 1rem;
	}
	&__day-name {
		color: $greyColor1 !important;
		text-transform: uppercase;
	}
	&__day {
		&:hover {
			color: $brandColor !important;
			background-color: #f8f7fa !important;
		}
	}
	&__day--selected,
	&__day--keyboard-selected {
		background-color: $brandColor !important;
		color: $whiteColor !important;
		&:hover {
			color: $whiteColor !important;
			background-color: $brandColor !important;
		}
	}
	&__day--outside-month {
		opacity: 0.4;
		&:hover {
			opacity: 1;
		}
	}
}
