@import "../../assets/mixins";
@import "../../assets/variables";

.purchase-card {
  position: relative;
  padding: 32px;
  cursor: pointer;
  user-select: none;
  &_selected {
    padding-top: 55px;
    border-width: 2px !important;
  }
  &.bordered {
    border: 1px solid $greyColor3;
    border-radius: 8px;
    transition: 0.2s border-radius ease-in-out, 0.2s border-color ease-in-out, 0.2s background ease-in-out;
    &.active {
      border-color: $brandColor;
      background: rgba($brandColor,0.05);
      .purchase-card__badge {
        background-color: $brandColor;
        color: $whiteColor;
      }
    }
  }
  &.with-border {
    border: 2px solid $greyColor;
    border-radius: 8px;
  }
  &.selected {
    border: 2px solid $brandColor;
    border-radius: 8px;
    .purchase-card__title {
      color: $black1;
    }
    .purchase-card__price {
      color: $brandColor;
    }
    .benefits__item {
      color: $siteColor;
    }
  }
  &__badge {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 11px 10px 12px;
    font-size: 12px;
    line-height: 1.16;
    font-weight: 600;
    letter-spacing: 0.5pt;
    text-transform: uppercase;
    text-align: center;
    background-color: $greyColor3;
    border-radius: 4px 4px 0 0;
    transition: 0.2s background-color ease-in-out, 0.2s color ease-in-out, 0.2s border-radius ease-in-out;
  }
  &__title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  &__price {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: $brandColor;
  }
  &__intro {
    display: flex;
    justify-content: space-between;
  }
  &__benefits {
    padding: 0;
    margin: 10px 0 0;
    list-style-type: none;
  }
  .benefits {
    &__item {
      @include body();
      color: rgba($siteColor,0.6);
    }
  }
}