@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.aside-layout {
	position: relative;
	display: flex;
	flex-direction: column;
	@media #{$desktop} {
		background-color: $greyColor4;
	}
	@media #{$tablet_desktop} {
		height: 100vh;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	@media #{$phone} {
		min-height: 100vh;
	}
	&__header {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		@media #{$desktop} {
			padding-top: 25px;
		}
		.header {
			@media #{$phone} {
				transition: 0.2s box-shadow ease-in-out;
			}
		}
		&_sticky .header {
			@media #{$phone} {
				box-shadow: 0 4px 7px rgba($siteColor, 0.05);
			}
		}
	}
	&__body {
		@media #{$desktop} {
			flex-grow: 1;
			flex-shrink: 1;
			display: flex;
		}
	}
	&__content {
		display: flex;
		background-color: $whiteColor;
		align-items: center;
		// @media #{$phone} {
		// 	padding: 20px;
		// 	min-height: calc(100% - 96px);
		// }
		@media #{$tablet_desktop} {
			min-height: calc(100% - 86px);
		}
		@media #{$desktop} {
			padding: 120px 10vw 60px;
			width: 50%;
			flex-shrink: 0;
		}
		@media #{$tablet} {
			padding: 120px 5vw 60px;
			margin: 0 auto;
			width: 65%;
			max-width: 500px;
		}
		@media #{$phone} {
			padding: 90px 20px 40px;
		}
	}
	&__footer {
		flex-shrink: 0;
		margin-top: auto;
	}
	&__aside {
		@media #{$desktop} {
			flex-shrink: 0;
			width: 50%;
			padding: 0 5vw;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&__image {
		flex-shrink: 0;
		width: 100%;
		max-width: 564px;
		&-i {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
