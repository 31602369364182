@import '../../assets/variables.scss';

.notifications {
	position: fixed;
	z-index: 11;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@media #{$tablet_desktop} {
		background-color: rgba($siteColor,0.4);
	}
	@media #{$phone} {
		padding: 40px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
		background-color: $whiteColor;
  }
	&__inner {
		@media #{$tablet_desktop} {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			padding: 60px 46px;
			width: 516px;
			background-color: $whiteColor;
			overflow-y: auto;
			overflow-x: hidden;
			-webkit-overflow-scrolling: touch;
		}
	}
	&__header {
		position: relative;
	}
	&__title {
		font-size: 20px;
		font-weight: 600;
	}
	&__subtitle {
		margin-top: 15px;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.4;
		color: $greyColor1;
	}
	&__close {
		position: absolute;
		top: 5px;
		right: 2px;
		width: 20px;
		height: 20px;
		padding: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;
		&:hover {
			&:before,
			&:after {
				@media #{$desktop} {
					background-color: $redColor;
				}
			}
		}
		&:before,
		&:after {
			position: absolute;
			content: '';
			top: 9px;
			left: 2px;
			width: 16px;
			height: 2px;
			background-color: $greyColor1;
			transition: .2s background-color ease-in-out;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
	&__wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100%;
		&-image {
			@media #{$tablet_desktop} {
				margin-bottom: 25px;
			}
			@media #{$phone} {
				margin-bottom: 5px;
			}
		}
		&-title {
			max-width: 270px;
			font-size: 14px;
			font-weight: 500;
			color: $greyColor1;
			text-align: center;
		}
	}
	&__list {
		@media #{$tablet_desktop} {
			padding-top: 40px;
		}
		@media #{$phone} {
			padding-top: 15px;
		}
	}
}
