@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.federal-providers {
  @media #{$tablet_desktop} {
    margin-bottom: 40px;
  }
  @media #{$phone} {
    margin-bottom: 20px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    @media #{$tablet_desktop} {
      gap: 8px;
    }
    @media #{$phone} {
      gap: 12px;
    }
  }
  &__button {
    flex-shrink: 1;
    flex-grow: 1;
    .btn__icon path {
      transition: 0.2s fill ease-in-out;
    }
    &_amazon {
      .btn__icon {
        margin-bottom: -4px;
      }
      &:hover,
      &:active {
        background: #FF9900;
        border-color: #FF9900;
        color: $whiteColor;
        .btn__icon path {
          fill: $whiteColor;
        }
      }
    }
    &_google {
      &:hover,
      &:active {
        background: #5383EC;
        border-color: #5383EC;
        color: $whiteColor;
        .btn__icon path {
          fill: $whiteColor;
        }
      }
    }
    &_apple {
      &:hover,
      &:active {
        background: $siteColor;
        border-color: $siteColor;
        color: $whiteColor;
        .btn__icon path {
          fill: $whiteColor;
        }
      }
    }
  }
}
