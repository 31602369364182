@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.payment-total-info {
	font-size: 16px;
	line-height: 1.4;
	font-weight: 600;
	@media #{$tablet_desktop} {
		margin: 60px 0 20px;
	}
	@media #{$phone} {
		margin-bottom: 20px;
	}
	&__item {
		margin-bottom: 4px;
		padding: 20px;
		background-color: $greyColor4;
		border-radius: 4px;
		border: 1px solid $greyColor9;
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
	}
	&__value {
		margin: -1px 0;
		font-size: 18px;
		color: $brandColor;
	}
	&__add {
		margin-bottom: 12px;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 500;
		color: $greyColor1;
	}
}