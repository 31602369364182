@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.notification {
	&.new {
		.notification__icon:before {
			position: absolute;
			content: '';
			top: 0;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			border: 1px solid $whiteColor;
			background-color: $redColor;
			@media #{$tablet_desktop} {
				right: 6px;
			}
			@media #{$phone} {
				right: 1px;
			}
		}
	}
	&__wrap {
		display: flex;
		align-items: flex-start;
		transition: 0.2s background-color ease-in-out;
		@media #{$tablet_desktop} {
			padding: 24px 46px;
			margin: 0 -46px;
			column-gap: 16px;
		}
		@media #{$phone} {
			padding: 20px;
			margin: 0 -20px;
			column-gap: 10px;
		}
		&:hover {
			@media #{$desktop} {
				background-color: $greyColor4;
			}
		}
	}
	&__icon {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		border-radius: 50%;
		background-color: $lightBlueColor2;
		@media #{$tablet_desktop} {
			width: 56px;
			height: 56px;	
		}
		@media #{$phone} {
			width: 45px;
			height: 45px;
		}
		&-i {
			@media #{$tablet_desktop} {
				width: 24px;
				height: 24px;
			}
			@media #{$phone} {
				width: 16px;
				height: 16px;
			}
			path {
				@media #{$phone} {
					fill: #03053d;
				}
			}
		}
	}
	&__title {
		font-weight: bold;
		color: $siteColor;
		@media #{$tablet_desktop} {
			font-size: 15px;
		}
		@media #{$phone} {
			font-size: 12px;
			line-height: 1.3;
		}
		a {
			color: $siteColor;
		}
	}
	&__date {
		font-size: 12px;
		line-height: 1.16;
		color: $greyColor1;
		@media #{$tablet_desktop} {
			margin-top: 15px;
		}
		@media #{$phone} {
			margin-top: 8px;
		}
	}
}
