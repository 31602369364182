@import '../../assets/variables.scss';

.project-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background: $whiteColor;
	border: 1px solid $greyColor5;
	outline: 0 none;
	color: $siteColor;
	text-align: left;
	transition: 0.2s box-shadow ease-in-out;
	cursor: pointer;
	@media #{$md_lg} {
		padding: 48px;
	}
	@media #{$tablet_sm} {
		padding: 30px 20px;
	}
	@media #{$phone} {
		padding: 20px;
	}
	&:hover {
		@media #{$desktop} {
			box-shadow: 0 4px 60px rgba($black1, 0.2);
		}
	}
	&__panel {
		margin-bottom: 12px;
		display: flex;
		flex-wrap: wrap;
	}
	&__badge {
		flex-shrink: 0;
		line-height: 1.4;
		font-weight: 700;
		border-radius: 4px;
		@media #{$desktop} {
			margin: 0 13px 13px 0;
			font-size: 14px;
		}
		@media #{$phone_tablet} {
			margin: 0 6px 6px 0;
			font-size: 12px;
		}
		@media #{$tablet_desktop} {
			padding: 8px 16px;
		}
		@media #{$phone} {
			padding: 6px 12px;
		}
		&.created {
			color: $brandColor;
			background: $lightBlueColor;
		}
		// &.in-progress {
		// 	color: $yellowColor;
		// 	background: $lightYellowColor;
		// }
		&.error {
			color: $redColor;
			background-color: rgba($redColor,0.05);
		}
		&.success {
			color: $successColor;
			background-color: rgba($successColor,0.1);
		}
		&.quote-ready,
		&.warning {
			color: $warningColor;
			background-color: rgba($warningColor,0.05);
		}
	}
	&__head {
		margin-bottom: 25px;
	}
	&__title {
		margin-bottom: 7px;
		font-size: 24px;
		font-weight: 700;
	}
	&__subtitle {
		font-size: 14px;
		font-weight: 500;
		color: $greyColor1;
	}
	&__text{
		font-size: 14px;
		line-height: 1.4;
		color: $greyColor1;
	}
	&__tags {
		margin: 24px 0 -12px;
		display: flex;
		flex-wrap: wrap;
	}
	&__tag {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		padding: 10px 12px;
		font-weight: 600;
		background-color: rgba($brandColor,0.05);
		border-radius: 4px;
		@media #{$tablet_desktop} {
			margin: 0 12px 12px 0;
			font-size: 14px;
		}
		@media #{$phone} {
			margin: 0 8px 8px 0;
			font-size: 12px;
		}
		&-icon {
			flex-shrink: 0;
			margin-right: 8px;
			@media #{$phone} {
				width: 18px;
				height: 18px;
			}
		}
		&-label {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
}
