@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.payment {
  padding-bottom: 20px;
  &__item {
    margin-bottom: 16px;
    padding: 0;
    width: 100%;
    border: 0 none;
    text-align: left;
    background: transparent;
    cursor: pointer;
    &:last-child {
      margin-bottom: 40px;
    }
  }
}
