@import '../../assets/variables.scss';

.footer {
	font-size: 14px;
	border-top: 1px solid rgba($siteColor,0.1);
	background-color: $whiteColor;
	@media #{$tablet_desktop} {
		display: flex;
		align-items: center;
	}
	@media #{$desktop} {
		padding: 32px 40px;
	}
	@media #{$tablet} {
		padding: 30px;
	}
	@media #{$phone} {
		padding: 20px;
	}
	&_simple {
		@media #{$desktop} {
			padding-left: 10vw;
			padding-right: 10vw;
		}
		@media #{$tablet} {
			padding-left: 5vw;
			padding-right: 5vw;
		}
  }
	&__info {
		font-weight: 500;
		color: $black1;
		@media #{$tablet_desktop} {
			flex-grow: 1;
			flex-shrink: 1;
		}
		@media #{$phone} {
			margin-bottom: 20px;
		}
		a {
			color: $black1;
			text-decoration: none;
			transition: 0.2s color ease-in-out;
			&:hover {
				color: $brandColor;
			}
		}
	}
	&__copyright {
		@media #{$tablet_desktop} {
			margin-left: 50px;
			flex-shrink: 0;
		}
	}
}