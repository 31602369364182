@import "../../assets/variables.scss";

.checkbox-wrapper {
  &.has-error {
    .checkbox-container .checkmark {
      border-color: $redColor;
    }
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  &-text {
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    color: $siteColor;
  }
  .checkbox-control {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    user-select: none;
    &:checked ~ .checkmark {
      background-color: rgba($brandColor,0.05);
      border-color: $brandColor;
      > svg {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: $whiteColor;
    border: 1px solid $greyColor6;
    border-radius: 4px;
    svg {
      display: none;
      position: absolute;
      transform: scale(0.8) translate(3px, 3px);
      path {
        fill: $brandColor;
      }
    }
  }
}