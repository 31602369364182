@import '../../assets/variables.scss';

.order-start {
	padding-bottom: 22px;
	@media #{$tablet_desktop} {
		min-height: calc(100vh - 275px);
		display: flex;
		flex-direction: column;
	}
	&__list {
		margin-bottom: 20px;
	}
	&__control {
		margin-bottom: 12px;
		width: 100%;
		background: $whiteColor;
		border: 1px solid $greyColor3;
		border-radius: 8px;
		font-family: $mainFont;
		text-align: left;
		user-select: none;
		cursor: pointer;
		transition: 0.2s border-color ease-in-out, 0.2s background ease-in-out;
		@media #{$tablet_desktop} {
			padding: 32px;
		}
		@media #{$phone} {
			padding: 16px;
		}
		&:hover {
			border-color: $brandColor;
		}
		&.active {
			background: rgba($brandColor,0.05);
			border-color: $brandColor;
		}
		&-title {
			margin-bottom: 8px;
			display: block;
			font-size: 16px;
			line-height: 1.4;
			font-weight: bold;
			color: $siteColor;
		}
		&-label {
			font-size: 14px;
			line-height: 1.4;
			font-weight: 500;
			color: $greyColor1;
		}
	}
	&__info {
		flex-shrink: 0;
		margin-top: auto;
	}
}