@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.settings {
  &__content {
    @media #{$tablet_desktop} {
      width: 100%;
      max-width: 568px;
    }
    @media #{$tablet} {
      margin: 0 auto;
    }
  }
  &__section {
    margin-bottom: 16px;
  }
  &__panel {
    background-color: $whiteColor;
    border-radius: 8px;
    @media #{$desktop} {
      padding: 24px 32px;
    }
    @media #{$tablet} {
      padding: 24px 20px;
    }
    @media #{$phone} {
      padding: 20px;
    }
    &_head {
      margin-bottom: 1px;
      display: flex;
    }
    &-icon {
      flex-shrink: 0;
      margin-right: 12px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: rgba($brandColor,0.1);
    }
    &-title {
      align-self: center;
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 15px;
      font-weight: bold;
    }
  }
  &__info {
    @media #{$tablet_desktop} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__auth {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    color: $siteColor;
    &-wrap {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
    &-title {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media #{$desktop} {
        max-width: 250px;
      }
      @media #{$tablet} {
        max-width: 200px;
      }
      @media #{$phone} {
        max-width: 100%;
      }
    }
    &-label {
      padding-top: 2px;
      font-size: 12px;
      font-weight: 500;
      color: rgba($siteColor,0.6);
    }
    &-icon {
      flex-shrink: 0;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $brandColor;
      border-radius: 50%;
      font-size: 12px;
      line-height: 1.2;
      font-weight: 600;
      color: $whiteColor;
      text-transform: uppercase;
      @media #{$tablet_desktop} {
        width: 45px;
        height: 45px;
      }
      @media #{$phone} {
        width: 32px;
        height: 32px;
      }
    }
  }
  &__auth-type {
    display: flex;
    align-items: center;
    &-icon {
      flex-shrink: 0;
      margin-right: 8px;
      width: 20px;
      height: auto;
    }
    &-label {
      flex-shrink: 0;
      line-height: 1.2;
      font-weight: 500;
      color: rgba($siteColor,0.6);
      @media #{$tablet_desktop} {
        padding-top: 1px;
        font-size: 14px;
      }
      @media #{$phone} {
        padding-left: 42px;
        font-size: 12px;
      }
    }
  }
  &__delete-account {
    display: inline-flex;
    align-items: center;
    border: 0 none;
    outline: 0 none;
    background: none;
    font-size: 15px;
    line-height: 1.2;
    color: $redColor;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      .settings__delete-account-icon {
        background-color: rgba($redColor,0.2);
      }
    }
    &-icon {
      flex-shrink: 0;
      margin-right: 12px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: rgba($redColor,0.05);
      transition: 0.2s background-color ease-in-out;
      &-i {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
  &__toggle {
    position: relative;
    display: block;
    width: 33px;
    height: 19px;
    border-radius: 10px;
    background: $greyColor6;
    border: 0 none;
    outline: 0 none;
    cursor: pointer;
    transition: 0.2s background ease-in-out;
    &.active {
      background: $brandColor;
      &:before {
        transform: translateX(14px);
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      width: 15px;
      border-radius: 50%;
      background-color: $whiteColor;
      transition: 0.2s transform ease-in-out;
    }
  }
  &__list {
    &-item {
      position: relative;
      margin-bottom: 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-control {
      position: absolute;
      top: 0;
      right: 0;
      &-link {
        font-size: 14px;
        line-height: 1.428;
        font-weight: bold;
        color: $brandColor;
        cursor: pointer;
        text-decoration: none;
        transition: 0.2s color ease-in-out;
        &:hover {
          color: $siteColor;
        }
      }
    }
    &-title {
      margin-bottom: 6px;
      font-size: 15px;
      font-weight: bold;
    }
    &-text {
      font-size: 12px;
      line-height: 1.16;
      font-weight: 500;
      color: $greyColor1;
    }
  }
}
