@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.change-password-success {
	padding: 15% 0 50px;
	text-align: center;
	&__image {
		margin-bottom: 40px;
		display: flex;
		justify-content: center;
	}
	&__title {
		margin-bottom: 12px;
		font-size: 24px;
		line-height: 1.4;
		font-weight: 600;
	}
	&__text {
		font-size: 14px;
		line-height: 1.4;
		font-weight: 500;
		color: $greyColor1;
	}
}
