@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.checkout-form {
	&__frame {
		padding: 20px;
		background-color: $lightGreyColor;
		border-radius: 5px;
	}
	&__payment-button {
		width: 100%;
	}
	&__badge {
		flex-shrink: 0;
		&-image {
			display: block;
			width: 109px;
			height: 30px;
		}
	}
	.StripeElement {
		margin-bottom: 16px;
	}
}