@import '../../assets/variables.scss';

.PhoneInput {
	height: 44px;
	background-color: $whiteColor;
	border: 1px solid $greyColor6;
	background: $whiteColor;
	border-radius: 4px;
	.has-error & {
		border-color: $redColor;
	}
	&--readOnly {
		border: 0 none;
		height: auto;
		pointer-events: none;
		.PhoneInputCountry {
			padding: 0 10px 0 0 !important;
		}
		.PhoneInputInput {
			height: auto !important;
			padding: 5px 0 !important;
		}
		.PhoneInputCountrySelectArrow {
			display: none !important;
		}
	}
	svg.PhoneInputCountryIconImg {
		display: none;
	}
	.PhoneInputCountry {
		margin: 0;
		padding: 0 16px;
	}
	.PhoneInputCountryIcon {
		height: 24px;
		background: url('/assets/images/phone-icon.svg') no-repeat 0 0;
		background-size: 24px 24px;
		&.PhoneInputCountryIcon--border {
			height: 16px;
			background: none;
		}
	}
	.PhoneInputCountryIcon--border {
		box-shadow: 0 0 0 1px $greyColor6;
		border-radius: 2px;
		overflow: hidden;
	}
	.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
		box-shadow: 0 0 0 1px $greyColor6;
	}
	.PhoneInputCountrySelectArrow {
		margin-left: 10px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 6px 5px 0;
		border-style: solid;
		border-color: $siteColor transparent transparent;
		transform: rotate(0);
		opacity: 1;
	}
	.PhoneInputInput {
		height: 42px;
		padding: 9px 16px 10px 0;
		font-family: $mainFont;
		font-size: 16px;
		line-height: 1.428;
		font-weight: 600;
		color: $siteColor;
		border: 0 none;
		border-radius: 4px;
		outline: 0 none;
		background: $whiteColor;
		&:-webkit-autofill {
			-webkit-text-fill-color: $siteColor;
			box-shadow: 0 0 0 1000px $whiteColor inset;
		}
		&::-webkit-input-placeholder {
			color: $greyColor1;
			font-weight: normal;
		}
		&::-moz-placeholder {
			color: $greyColor1;
			font-weight: normal;
		}
		&:-moz-placeholder {
			color: $greyColor1;
			font-weight: normal;
		}
		&:-ms-input-placeholder {
			color: $greyColor1;
			font-weight: normal;
		}
	}
}