@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.react-select-custom-caret {
	margin-right: 13px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 4px 0;
	border-color: $siteColor transparent transparent transparent;
	&.active {
		transform: rotate(180deg);
	}
}