@import './variables.scss';

.form-group {
	margin-bottom: 40px;
}

.fields-group-separator {
	margin: 40px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	line-height: 1.4;
	font-weight: 500;
	text-align: center;
	&:before,
	&:after {
		content: '';
		flex-grow: 1;
		flex-shrink: 1;
		height: 1px;
		opacity: 0.2;
	}
	&:before {
		background: linear-gradient(to right, rgba($siteColor,0) 0%, $siteColor 100%)
	}
	&:after {
		background: linear-gradient(to right, $siteColor 0%, rgba($siteColor,0) 100%)
	}
	&-inner {
		flex-shrink: 0;
		margin: 0 10px;
	}
}

.fields-group {
  margin-bottom: 24px;
	&_bottom-offset {
		margin-bottom: 40px;
	}
}
.fields-group-md .form-group {
	margin-bottom: 16px;
}
.fields-group-title {
	margin-bottom: 10px;
	line-height: 1.4;
	font-weight: 600;
	background-color: $greyColor4;
	border-radius: 4px;
	sup {
		top: 0;
		font-size: 100%;
		color: $redColor2;
	}
	@media #{$tablet_desktop} {
		padding: 16px;
		font-size: 16px;
	}
	@media #{$phone} {
		padding: 12px;
		font-size: 14px;
	}
	&.error {
		color: #EB5757;
		&::before {
			content: ''; /* Required for pseudo-elements */
			display: inline-block; /* Allows for proper alignment and spacing */
			width: 24px; /* Set width of the SVG */
			height: 24px; /* Set height of the SVG */
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIwNzZfMTAzNzEpIj4KPHBhdGggZD0iTTEwLjcyMTggMi41TDE4LjY2MDIgMTYuMjVDMTguNzMzMyAxNi4zNzY3IDE4Ljc3MTggMTYuNTIwNCAxOC43NzE4IDE2LjY2NjdDMTguNzcxOCAxNi44MTI5IDE4LjczMzMgMTYuOTU2NiAxOC42NjAyIDE3LjA4MzNDMTguNTg3IDE3LjIxIDE4LjQ4MTggMTcuMzE1MiAxOC4zNTUyIDE3LjM4ODRDMTguMjI4NSAxNy40NjE1IDE4LjA4NDggMTcuNSAxNy45Mzg1IDE3LjVIMi4wNjE4M0MxLjkxNTU1IDE3LjUgMS43NzE4NSAxNy40NjE1IDEuNjQ1MTcgMTcuMzg4NEMxLjUxODQ5IDE3LjMxNTIgMS40MTMyOSAxNy4yMSAxLjM0MDE2IDE3LjA4MzNDMS4yNjcwMiAxNi45NTY2IDEuMjI4NTEgMTYuODEyOSAxLjIyODUyIDE2LjY2NjdDMS4yMjg1MiAxNi41MjA0IDEuMjY3MDIgMTYuMzc2NyAxLjM0MDE2IDE2LjI1TDkuMjc4NDkgMi41QzkuMzUxNjQgMi4zNzMzMyA5LjQ1NjgzIDIuMjY4MTQgOS41ODM1MSAyLjE5NTAxQzkuNzEwMTkgMi4xMjE4OCA5Ljg1Mzg5IDIuMDgzMzcgMTAuMDAwMiAyLjA4MzM3QzEwLjE0NjQgMi4wODMzNyAxMC4yOTAxIDIuMTIxODggMTAuNDE2OCAyLjE5NTAxQzEwLjU0MzUgMi4yNjgxNCAxMC42NDg3IDIuMzczMzMgMTAuNzIxOCAyLjVaTTMuNTA1MTYgMTUuODMzM0gxNi40OTUyTDEwLjAwMDIgNC41ODMzNEwzLjUwNTE2IDE1LjgzMzNaTTkuMTY2ODMgMTMuMzMzM0gxMC44MzM1VjE1SDkuMTY2ODNWMTMuMzMzM1pNOS4xNjY4MyA3LjVIMTAuODMzNVYxMS42NjY3SDkuMTY2ODNWNy41WiIgZmlsbD0iI0VCNTc1NyIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIwNzZfMTAzNzEiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==");
			background-size: contain; /* Ensure the SVG scales properly */
			margin-right: 8px; /* Space between the icon and the text */
			vertical-align: middle;
		}
	};
}

.fields-group-subtitle {
	margin-bottom: 12px;
	font-size: 14px;
	line-height: 1.4;
	font-weight: 600;
	color: $siteColor;
}

.fields-addition {
  margin-top: -5px;
}

.form-control {
	display: block;
	width: 100%;
	padding: 10px 16px 11px;
	height: 44px;
	font-family: $mainFont;
	font-size: 16px;
	line-height: 1.428;
	font-weight: 600;
	color: $siteColor;
	border: 1px solid $greyColor6;
	background: $whiteColor;
	border-radius: 4px !important;
	transition: 0.2s border-color ease-in-out;
	outline: 0 none;
	-webkit-appearance: none;
	&:focus {
		border-color: rgba($brandColor,0.5);
	}
	&:-webkit-autofill {
		-webkit-text-fill-color: $siteColor;
		box-shadow: 0 0 0 1000px $whiteColor inset;
	}
	&::-webkit-input-placeholder {
		color: $greyColor1;
		font-weight: normal;
	}
	&::-moz-placeholder {
		color: $greyColor1;
		font-weight: normal;
	}
	&:-moz-placeholder {
		color: $greyColor1;
		font-weight: normal;
	}
	&:-ms-input-placeholder {
		color: $greyColor1;
		font-weight: normal;
	}
	&[type='password'] {
		padding-right: 40px;
	}
}

textarea.form-control {
	resize: vertical;
	height: auto;
	min-height: 44px;
}

.error-message {
	padding-top: 6px;
	font-size: 12px;
	font-weight: 500;
	color: $redColor;
}

.has-error {
	.error-message {
		display: block;
	}
	.form-control {
		color: $redColor;
		border-color: $redColor;
	}
}

.where-to-find-tooltip {
	font-size: 12px;
	line-height: 1.16;
	font-weight: 600;
	a {
		color: $brandColor;
		transition: 0.2s color ease-in-out;
		&:hover {
			color: $siteColor;
		}
	}
	&-list {
		padding-left: 15px;
		margin: 0;
		&__item {
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&-image {
		margin: -24px;
		overflow: hidden;
		border-radius: 7px;
		&-i {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}

input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}