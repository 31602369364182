@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.payment-type {
  &__nav {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
  &__item {
    flex-shrink: 0;
    width: calc(50% - 8px);
    border: 1px solid $greyColor3;
    background: transparent;
    transition: 0.2s border-color ease-in-out, 0.2s background ease-in-out;
    cursor: pointer;
    @media #{$tablet_desktop} {
      padding: 16px 24px 16px 56px;
      border-radius: 8px;
    }
    @media #{$phone} {
      padding: 16px 16px 16px 36px;
      border-radius: 4px;
    }
    &.active {
      border-color: $brandColor;
      background: rgba($brandColor, 0.05);
      .payment-type__item-wrap:before {
        @media #{$tablet_desktop} {
          border-width: 5px;
        }
        @media #{$phone} {
          border-width: 4px;
        }
      }
    }
    &-wrap {
      position: relative;
      text-align: left;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        border-radius: 50%;
        border: 2px solid $brandColor;
        transition: 0.2s border-width ease-in-out;
        @media #{$tablet_desktop} {
          left: -35px;
          width: 18px;
          height: 18px;
        }
        @media #{$phone} {
          left: -24px;
          width: 14px;
          height: 14px;
        }
      }
    }
    &-title {
      line-height: 1.2;
      font-weight: bold;
      color: $brandColor;
      @media #{$tablet_desktop} {
        font-size: 14px;
      }
      @media #{$phone} {
        font-size: 12px;
      }
    }
  }
}
