@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.password-validity {
    display: flex;
    flex-direction: column;
    padding: 10px 0;

    &-item {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &__icon-wr {
            margin-right: 5px;
        }

        &__icon {
           width: 18px;
           height: 18px;
        }

        &__text {
           @include btn-sm();
           font-weight: 400;
           color: $black1;
        }
    }
}