@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.success-box {
  padding: 15% 0 50px;
	text-align: center;
	&__image {
		margin: 0 auto;
		@media #{$tablet_desktop} {
			width: 310px;
		}
		@media #{$phone} {
			max-width: 310px;
			width: 100%;
		}
	}
	&__title {
		margin-bottom: 12px;
		font-size: 24px;
		line-height: 1.4;
		font-weight: 600;
	}
	&__text {
		font-size: 14px;
		line-height: 1.4;
		font-weight: 500;
		color: $greyColor1;
	}
}
