@import '../../assets/variables.scss';

.forgot-password-form {
	flex-shrink: 0;
	width: 100%;
	&__head {
		margin-bottom: 20px;
	}
	&__title {
		@media #{$tablet_desktop} {
			margin: 0 0 10px;
			line-height: 1.2;
			font-weight: 600;
		}
		@media #{$desktop} {
			font-size: 40px;
		}
		@media #{$tablet} {
			font-size: 34px;
		}
		@media #{$phone} {
			margin: 0 0 8px;
			font-size: 24px;
			line-height: 1.1;
			font-weight: 700;
		}
	}
	&__text {
		font-size: 14px;
		line-height: 1.4;
		font-weight: 500;
	}
	&__info {
		margin-top: 40px;
		line-height: 1.4;
		font-weight: 600;
		@media #{$tablet_desktop} {
			font-size: 14px;
		}
		@media #{$phone} {
			font-size: 12px;
		}
		a {
			color: $brandColor;
			text-decoration: none;
			transition: 0.2s color ease-in-out;
			&:hover {
				color: $siteColor;
			}
		}
	}
}
