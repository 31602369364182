@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.project-progress {
	&__title {
		@include h2();
		margin: 0 0 20px;
		word-break: break-word;
	}
}

.progress-accordion {
	border: 0 none !important;
	background-color: transparent !important;
	.rc-collapse-expand-icon {
		display: none;
	}
	.rc-collapse-item {
		margin-bottom: 4px;
		border-top: 0 none !important;
	}
	.rc-collapse-content {
		padding: 0;
		background-color: transparent;
		& > .rc-collapse-content-box {
			margin: 0;
		}
	}
	.inner {
		@media #{$tablet_desktop} {
			display: flex;
			justify-content: flex-start;
		}
	}
	&__body {
		width: 100%;
		margin-top: 4px;
		border-radius: 4px;
		background-color: $whiteColor;
		@media #{$tablet_desktop} {
			padding: 32px;
		}
		@media #{$phone} {
			padding: 15px 10px;
		}
		&.row-info-wrapper {
			@media #{$phone} {
				display: flex;
			}
		}
		.inner {
			@media #{$phone} {
				display: flex;
			}
		}
		.row-info-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media #{$phone} {
				display: flex;
			}
			&__list {
				padding-left: 15px;
				margin-top: 5px;
			}
			&__info {
				align-self: center;
				.fields-group {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			&__icon {
				margin-right: 12px;
				svg {
					display: block;
					@media #{$phone} {
						width: 28px;
						height: 28px;
					}
				}
			}
			&__title {
				display: block;
				@include label-lg();
			}
			&__text {
				margin-top: 6px;
				@include body();
				color: $black1;
				opacity: 0.7;
			}
			&__html-text {
				* {
					margin: 0;
				}
				ul, ol {
					@extend .row-info-wrapper__list;
				}
			}
			&__list-item {
				@extend .row-info-wrapper__text;
			}
		}
		.body-item {
			flex: 1;
			@media #{$phone} {
				padding-left: 40px;
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__info-block {
				display: flex;
				flex-direction: column;
				margin-bottom: 16px;
				&.right-padding {
				    padding-right: 20px;
			    }
				&:last-child {
					margin-bottom: 0;
				}
			}
			&__title {
				@include body();
				color: $black1;
				margin-top: 0;
				margin-bottom: 20px;
			}
			&__label {
				@include label-sm();
				color: $black1;
				opacity: 0.45;
			}
			&__text {
				@include caption-sm();
				color: $black1;
			}
		}
	}
	&__expand-btn {
		border-radius: 50%;
		padding: 0;
		width: 40px;
		height: 40px;
		background-color: rgba(51, 73, 131, 0.1);
		margin-left: 10px;
		&:hover {
			background-color: rgba(51, 73, 131, 0.3);
		}
	}
	.panel-header {
		display: block !important;
		padding: 0 !important;
		cursor: pointer !important;
		&[aria-disabled='false'] {
			cursor: default;
		}
		&[aria-expanded='true'] {
			.progress-accordion__button {
				border-bottom: 0;
			}
		}
	}
	&__button {
		display: flex;
		justify-content: space-between;
		padding: 18px;
		background-color: $whiteColor;
		border-radius: 4px;
	}
	&__heading-left {
		flex-grow: 1;
		flex-shrink: 1;
		position: relative;
		padding-left: 32px;
		&-footer {
			margin-top: 15px;
		}
	}
	&__heading-right {
		flex-shrink: 0;
		margin-left: 30px;
	}
	&__heading-left-icon {
		position: absolute;
		top: 0;
		left: 0;
		svg {
			display: block;
		}
	}
	&__heading-title {
		@include h5();
		color: $siteColor;
		margin: 0;
	}
	&__heading-text {
		padding-top: 6px;
		@include body();
		color: $siteColor;
		opacity: 0.7;
	}
}