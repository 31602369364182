@import "../../assets/mixins";
@import "../../assets/variables";

.form-radio-group {
  &__wrap {
    display: flex;
    justify-content: flex-start; /* Align items to the start of the container */
    align-items: center; /* Vertically center the items if needed */
    margin: 0; /* Remove outer margins */
    padding: 0; /* Remove outer padding */
  }

  &__item {
    flex-shrink: 0;
    width: auto; /* Allow items to take up only as much space as needed */
    padding: 0; /* Remove padding to eliminate spacing between buttons */
    margin: 5px; /* Remove any margins to ensure no space between buttons */
  }

  .control-top-labels {
    margin-bottom: 12px;
  }

  .form-label {
    color: $siteColor;
	font-size: 16px;
  }

  .description-label {
	margin-top: 10px;
    color: $siteColor;
	font-size: 12px;
  }
}
