@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.text-red {
	color: red;
}
  
.project-details {
	flex-shrink: 0;
	width: 100%;
	@media #{$lg} {
		display: flex;
	}
	&__loading {
		flex-shrink: 0;
		width: 100%;
	}
	&__body {
		@media #{$lg} {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
	&__aside {
		@media #{$lg} {
			flex-shrink: 0;
			min-width: 282px;
			width: 30%;
			max-width: 550px;
			margin-left: 20px;
		}
		@media #{$tablet_md} {
			padding-top: 11px;
		}
	}
	&__nav {
		&-item {
			width: 100%;
			display: flex;
			align-items: center;
			font-size: 14px;
			font-weight: 600;
			color: $greyColor1;
			border-width: 1px;
			border-style: solid;
			border-color: transparent;
			border-radius: 4px;
			background: transparent;
			cursor: pointer;
			user-select: none;
			outline: 0 none;
			transition: 0.2s background ease-in-out, 0.2s border-color ease-in-out, 0.2s color ease-in-out;
			@media #{$tablet_desktop} {
				padding: 16px 20px;
			}
			@media #{$phone} {
				position: relative;
				padding: 14px 44px 14px 12px;
				&:after {
					content: '';
					position: absolute;
					top: 21px;
					right: 17px;
					border-style: solid;
					border-width: 5px 4px 0;
					border-color: $greyColor1 transparent transparent;
				}
			}
			&:hover {
				background: rgba($whiteColor,0.9);
			}
			&.active {
				color: $siteColor;
				border-color: rgba($siteColor,0.1);
				background: $whiteColor;
			}
			&[disabled] {
				cursor: default;
				pointer-events: none;
			}
		}
		&-icon {
			flex-shrink: 0;
			width: 18px;
			height: 18px;
			margin-right: 10px;
		}
	}
	&__delete {
		width: 100%;
		display: flex;
		align-items: center;
		border: 0 none;
		outline: 0 none;
		font-size: 14px;
		font-weight: 600;
		color: $redColor2;
		background: none;
		border-radius: 4px;
		transition: 0.2s background ease-in-out;
		cursor: pointer;
		@media #{$tablet_desktop} {
			padding: 16px 20px;
		}
		@media #{$phone} {
			padding: 12px 12px 12px 14px;
		}
		&:hover {
			@media #{$desktop} {
      	background: rgba($redColor2,0.1);
			}
    }
		&-icon {
			flex-shrink: 0;
			@media #{$tablet_desktop} {
				margin-right: 10px;
				width: 18px;
				height: 18px;
			}
			@media #{$phone} {
				margin-right: 7px;
				width: 20px;
				height: 20px;
			}
		}
	}
	&__head {
		background-color: $whiteColor;
		@media #{$tablet_desktop} {
			margin-bottom: 20px;
			padding: 24px;
			display: flex;
			align-items: center;
			border-radius: 4px;
			border: 1px solid rgba($siteColor,0.1);
		}
		@media #{$phone} {
			margin: -20px -20px 8px;
			padding: 16px 20px;
			border-bottom: 1px solid rgba($siteColor,0.1);
		}
		&-wrap {
			@media #{$tablet_desktop} {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}
		&-aside {
			@media #{$tablet_desktop} {
				flex-shrink: 0;
			}
			@media #{$phone} {
				margin-top: 12px;
			}
		}
	}
	&__title {
		margin: 0 0 5px;
		font-size: 20px;
		line-height: 1.2;
		font-weight: bold;
	}
	&__subtitle {
		font-size: 14px;
		font-weight: bold;
		color: $greyColor8;
	}
	&__badge {
		padding: 8px 12px;
		line-height: 1.4;
		font-weight: 700;
		border-radius: 4px;
		@media #{$desktop} {
			font-size: 14px;
		}
		@media #{$tablet} {
			font-size: 12px;
		}
		@media #{$phone} {
			display: inline-flex;
			font-size: 14px;
		}
		&.created {
			color: $brandColor;
			background: $lightBlueColor;
		}
		// &.in-progress {
		// 	color: $yellowColor;
		// 	background: $lightYellowColor;
		// }
		&.error {
			color: $redColor;
			background-color: rgba($redColor,0.05);
		}
		&.success {
			color: $successColor;
			background-color: rgba($successColor,0.1);
		}
		&.quote-ready,
		&.warning {
			color: $warningColor;
			background-color: rgba($warningColor,0.05);
		}
	}
	&__widget {
		border-radius: 4px;
		border: 1px solid rgba($siteColor,0.1);
		background-color: $whiteColor;
		@media (min-height: 700px) and (min-width: #{$mdWidth}) {
			position: sticky;
			top: 0;
		}
		@media #{$lg} {
			padding: 10%;
			text-align: center;
		}
		@media #{$md} {
			align-items: center;
		}
		@media #{$tablet_md} {
			display: flex;
			padding: 30px;
		}
		&-image {
			@media #{$lg} {
				margin-bottom: 20px;
			}
			@media #{$md} {
				width: 230px;
			}
			@media #{$tablet_md} {
				flex-shrink: 0;
				margin-right: 20px;
			}
			@media #{$tablet_sm} {
				width: 130px;
			}
			.animation-image {
				@media #{$lg} {
					margin-top: -30px;
				}
			}
			&-i {
				@media #{$md_lg} {
					width: 162px;
					height: 162px;
				}
				@media #{$sm} {
					width: 142px;
					height: 142px;
				}	
			}
		}
		&-title {
			margin-bottom: 8px;
			line-height: 1.2;
			font-weight: bold;
			color: $brandColor;
			@media #{$md_lg} {
				font-size: 18px;
			}
			@media #{$phone_sm} {
				font-size: 14px;
			}
		}
		&-text {
			line-height: 1.4;
			font-weight: 500;
			color: $greyColor1;
			@media #{$md_lg} {
				font-size: 14px;
			}
			@media #{$phone_sm} {
				font-size: 12px;
			}
		}
		&-footer {
			@media #{$md_lg} {
				margin-top: 30px;
			}
			@media #{$tablet_sm} {
				margin-top: 20px;
			}
		}
		&-button {
			min-width: 162px;
			@media #{$lg} {
				width: 75%;
			}
		}
	}
	&__content {
		@media #{$tablet_desktop} {
			display: flex;
			align-items: flex-start;
		}
		&-aside {
			@media #{$tablet_desktop} {
				flex-shrink: 0;
				margin-right: 16px;
			}
			@media #{$md_lg} {
				min-width: 222px;
				max-width: 400px;
				width: 25%;
			}
			@media #{$tablet_sm} {
				width: 195px;
			}
			@media (min-height: 700px) and (min-width: #{$mdWidth}) {
				position: sticky;
				top: 0;
			}
		}
		&-body {
			@media #{$md_lg} {
				flex-grow: 1;
				flex-shrink: 1;
			}
			@media #{$tablet_sm} {
				flex-shrink: 0;
				width: calc(100% - 211px);
			}
		}
	}
	&__panel {
		@media #{$tablet_desktop} {
			margin-bottom: 11px;
			border-radius: 4px;
			border: 1px solid rgba($siteColor,0.1);
			background-color: $whiteColor;
		}
		@media #{$phone} {
			margin-bottom: 20px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&-wrap {
			@media #{$tablet_desktop} {
				display: flex;
				padding: 28px;
			}
		}
		&-footer {
			display: flex;
			border-top: 1px solid rgba($siteColor,0.1);
			@media #{$tablet_desktop} {
				padding: 28px;
				justify-content: space-between;
			}
			@media #{$phone} {
				flex-direction: column;
				padding-top: 16px;
				margin-top: 16px;
			}
			.project-details__panel-button {
				@media #{$tablet_desktop} {
					flex-shrink: 0;
					width: calc(50% - 6px);
				}
				&:first-child {
					&:last-child {
						@media #{$tablet_desktop} {
							width: 100%;
						}
					}
				}
			}
		}
		&-main {
			@media #{$tablet_desktop} {
				flex-grow: 1;
				flex-shrink: 1;
				position: relative;
				padding-left: 34px;
			}
		}
		&-aside {
			flex-shrink: 0;
			margin-left: 20px;
		}
		&-button {
			min-width: 134px;
			@media #{$phone} {
				width: 100%;
				margin-bottom: 8px;
			}
			&:last-child {
				@media #{$phone} {
					margin-bottom: 0;
				}
			}
		}
		&-icon {
			position: absolute;
			top: 3px;
			left: 0;
			svg {
				display: block;
				width: 18px;
				height: 18px;
			}
		}
		&-title {
			margin: 0 0 8px;
			font-size: 16px;
			font-weight: 600;
		}
		&-text {
			font-size: 12px;
			font-weight: 500;
			color: $greyColor1;
			margin-bottom: 20px;
			@media #{$tablet_desktop} {
				line-height: 1.16;
			}
			@media #{$phone} {
				line-height: 1.5;
			}
		}
	}
	&__tab {
		@media #{$phone} {
			margin-top: -1px;
			padding: 16px;
			background-color: $whiteColor;
			border-radius: 4px;
			border: 1px solid rgba($siteColor,0.1);
		}
	}
	&__section {
		@media #{$tablet_desktop} {
			padding: 28px;
			border-radius: 4px;
			border: 1px solid rgba($siteColor,0.1);
			background-color: $whiteColor;
		}
	}
	&__section_upselling {
		@media #{$tablet_desktop} {
			margin-top: 10px;
			padding: 28px;
			border-radius: 4px;
			border: 1px solid rgba($siteColor,0.1);
			background-color: $whiteColor;
		}
		.payment-chooser {
			&__wrap {
				background: #F6F9FF;
				padding: 10px;
			}
			.project-details__panel-text {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
			}
			.project-details__panel-text svg {
				margin-right: 5px;
				path {
					fill: url(#pricingGradient);
				}
			}
			.project-details__panel-text span {
				background: linear-gradient(90.04deg, #7083D9 22.61%, #334983 72.14%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}
	}
	&__info {
		padding-bottom: 40px;
		text-align: center;
		&-image {
			margin: 0 auto;
			width: 100%;
			max-width: 400px;
		}
		&-content {
			width: 80%;
			margin: 0 auto;
		}
		&-title {
			margin-bottom: 8px;
			font-size: 16px;
			font-weight: bold;
		}
		&-text {
			font-size: 12px;
			line-height: 1.5;
			color: $greyColor1;
		}
	}
	&__file {
		display: flex;
		align-items: center;
		&-icon {
			flex-shrink: 0;
			margin-right: 16px;
			width: 58px;
			height: 58px;
			border-radius: 4px;
			background: $greyColor4;
			display: flex;
			justify-content: center;
			align-items: center;
			&-i {
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				&_md {
					width: 26px;
					height: auto;
				}
			}
		}
		&-wrap {
			flex-shrink: 0;
			width: calc(100% - 74px);
		}
		&-name {
			width: 100%;
			font-size: 14px;
			font-weight: 600;
			color: $siteColor;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			@media #{$md_lg} {
				max-width: 30vw;
			}
		}
		&-size {
			font-size: 13px;
    	color: $greyColor1;
		}
	}

	.fields-group {
		margin-bottom: 32px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.rc-collapse {
		border: 0 none;
		background-color: transparent;
	}
	.rc-collapse-item {
		border: 0 none !important;
		margin-bottom: 20px;
	}
	.rc-collapse-header {
		display: flex;
		flex-direction: row-reverse;
		padding: 0 !important;
		color: $siteColor !important;
		background-color: $greyColor4;
		border-radius: 4px;
	}
	.rc-collapse-item-active .rc-collapse-header .arrow {
		transform: rotate(180deg);
	}
	.rc-collapse-expand-icon {
		flex-shrink: 0;
		margin: 0 20px;
		.arrow {
			top: 0 !important;
			margin: 0 !important;
			border-style: solid !important;
			border-width: 5px 4px 0 !important;
			border-color: $siteColor transparent transparent transparent !important;
		}
	}
	.rc-collapse-header-text {
		flex-grow: 1;
		flex-shrink: 1;
		.fields-group-title {
			margin: 0;
		}
	}
	.rc-collapse-content {
		padding: 0;
		.rc-collapse-content-box {
			margin-bottom: 0;
		}
	}
}
