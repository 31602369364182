@import "../../assets/variables.scss";

.circle-spinner {
	display: flex;
	justify-content: center;
	padding: 30px 0;
	&__item {
		flex-shrink: 0;
		width: 30px;
		height: 30px;
		border: 4px solid rgba($brandColor,0.1);
    border-radius: 50%;
    border-top-color: $brandColor;
    animation: rotate-spinner 0.5s linear;
    animation-iteration-count: infinite;
	}
}

.circle-spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &-screen {
        height: 100vh;
        width: 100vw;
    }
}

@keyframes rotate-spinner {
  100% {
    transform: rotate(360deg);
  }
}