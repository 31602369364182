@import '../../assets/variables.scss';

.login-form {
	flex-shrink: 0;
	width: 100%;
	&__title {
		@media #{$tablet_desktop} {
			margin: 0 0 10px;
			line-height: 1.2;
			font-weight: 600;
		}
		@media #{$desktop} {
			font-size: 40px;
		}
		@media #{$tablet} {
			font-size: 34px;
		}
		@media #{$phone} {
			margin: 0 0 8px;
			font-size: 24px;
			line-height: 1.1;
			font-weight: 700;
		}
	}
	&__subtitle {
		font-size: 14px;
		font-weight: 500;
		@media #{$tablet_desktop} {
			margin-bottom: 20px;
		}
		@media #{$phone} {
			margin-bottom: 16px;
		}
	}
	&__label {
		font-weight: 700;
		@media #{$tablet_desktop} {
			margin-bottom: 20px;
			font-size: 15px;
		}
		@media #{$phone} {
			margin-bottom: 16px;
			font-size: 14px;
		}
	}
	&__forgot-password {
		float: right;
		font-size: 12px;
		line-height: 1.4;
		font-weight: 600;
		color: $siteColor;
		text-decoration: none;
		transition: 0.2s color ease-in-out;
		@media #{$tablet_desktop} {
			margin-top: 2px;
		}
		&:hover {
			color: $brandColor;
		}
	}
	&__info {
		margin-bottom: 12px;
		line-height: 1.4;
		font-weight: 600;
		text-align: center;
		@media #{$tablet_desktop} {
			font-size: 14px;
		}
		@media #{$phone} {
			font-size: 12px;
		}
		a {
			color: $brandColor;
			text-decoration: none;
			transition: 0.2s color ease-in-out;
			&:hover {
				color: $siteColor;
			}
		}
	}
	// &__privacy {
	// 	line-height: 1.4;
	// 	font-weight: 600;
	// 	text-align: center;
	// 	@media #{$tablet_desktop} {
	// 		font-size: 14px;
	// 	}
	// 	@media #{$phone} {
	// 		font-size: 12px;
	// 	}
	// 	a {
	// 		display: inline-block;
	// 		line-height: 1.1;
	// 		border-bottom: 1px solid rgba($siteColor,0.3);
	// 		color: $siteColor;
	// 		text-decoration: none;
	// 		transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out;
	// 		&:hover {
	// 			color: $brandColor;
	// 			border-color: rgba($brandColor,0.3);
	// 		}
	// 	}
	// }
	&__error {
		margin-bottom: 20px;
	}
}
