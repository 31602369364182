@import './variables.scss';
@import './mixins.scss';

.btn {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	justify-content: center;
	align-items: center;
	padding: 16px 22px 17px;
	border-radius: 4px;
	border: 0 none;
	font-family: $mainFont;
	font-size: 15px;
	line-height: 1.3;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	outline: 0 none;
	user-select: none;
	transition: 0.2s color ease-in-out, 0.2s background ease-in-out, 0.2s border-color ease-in-out;
	&-default {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
		background: $brandColor;
		color: $whiteColor;
		&:hover,
		&:active {
			background: $siteColor;
			color: $whiteColor;
		}
		.icon-forward, .icon-back {
			path {
				fill: $whiteColor;
			}
		}
	}
	&-light {
		background: $lightBlueColor;
		color: $brandColor;
		&:hover,
		&:active {
			background: $brandColor;
			color: $whiteColor;
			path {
				fill: $whiteColor;
			}
		}
	}
	&-lightgrey {
		color: $siteColor;
		background: $greyColor4;
	}
	&-lightred {
		background: $lightRedColor;
		color: $redColor2;
		&:hover {
			background: $redColor2;
			color: $whiteColor;
			path {
				fill: $whiteColor;
			}
		}
	}
	&-fullwidth {
		width: 100%;
	}
	&-halfwidth {
		width: 47%;
		margin: 5px;
	}
	&-grey {
		color: $brandColor;
		background: rgba($brandColor,0.13);
		&:hover,
		&:active {
			color: $brandColor;
			background: rgba($brandColor,0.4);
		}
	}
	&-bordered {
		border: 1px solid $greyColor2;
		background: transparent;
		&:hover,
		&:active {
			background: $greyColor2;
		}
	}
	&-link {
		background: none;
		color: $greyColor;
		padding: 8px 30px 10px;
	}
	&-link-default {
		padding-left: 0;
		padding-right: 0;
		background: none;
		color: $siteColor;
		&:hover {
			color: $brandColor;
		}
	}
	&-text-link {
		@include label();
		cursor: pointer;
		background: transparent;
		border: 0;
		padding: 0 !important;
		margin: 0;
		color: $brandColor !important;
		text-decoration: underline;
		&:hover {
			color: $siteColor !important;
		}
	}
	&-icon {
		cursor: pointer;
		background: transparent;
		border: 0;
		padding: 0;
		margin: 0;
	}
	&-sm {
		@include btn-sm();
		border-radius: 50px;
		padding: 8px 18px;
	}
	&-md {
		padding: 13px 30px 15px;
		border-radius: 23px;
	}
	&.plain {
		background: none;
		padding: 0;
		margin: 0;
		border: 0;
	}
	&.thin {
	  padding: 0;
	}
	.icon {
		&-back {
			margin-right: 7px;
		}
		&-forward {
			margin: 2px 0 0 7px;
		}
	}
	path {
		transition: 0.2s fill ease-in-out;
	}
}
