$mainFont: 'Manrope', sans-serif;
$brandColor: #334983;
$siteColor: #090c15;
$black1: #000921;
$btnBlackColor: #070707;
$whiteColor: #ffffff;
$greyColor: #999999;
$greyColor1: #717786;
$greyColor2: #e9e9e9;
$greyColor3: #cacaca;
$greyColor4: #f8f8f8;
$greyColor5: #ececec;
$greyColor6: #d1d1d9;
$greyColor7: #898989;
$greyColor8: #6a7380;
$greyColor9: #eaeaee;
$lightGreyColor: #f9f9f9;
$lightBlueColor: #f6f9ff;
$lightBlueColor2: #e5e9f3;
$redColor: #ff4242;
$redColor2: #eb5757;
$lightRedColor: #fff8f8;
$warningColor: #fc8e51;
$successColor: #3ec99f;
$yellowColor: #ffb800;
$lightYellowColor: #fffcf2;

$lgWidth: 1440px;
$mdWidth: 1260px;
$smWidth: 1024px;
$tabletWidth: 768px;
$phoneXs: 350px;

$lg: "(min-width: #{$lgWidth})";
$md: "(min-width: #{$mdWidth}) and (max-width: #{$lgWidth - 1})";
$sm: "(min-width: #{$smWidth}) and (max-width: #{$mdWidth - 1})";
$tablet: "(min-width: #{$tabletWidth}) and (max-width: #{$smWidth - 1})";
$phone: "(max-width: #{$tabletWidth - 1})";
$desktop: "(min-width: #{$smWidth})";

$md_lg: "(min-width: #{$mdWidth})";
$sm_md: "(min-width: #{$smWidth}) and (max-width: #{$lgWidth - 1})";
$tablet_desktop: "(min-width: #{$tabletWidth})";
$phone_tablet: "(max-width: #{$smWidth - 1})";
$phone_sm: "(max-width: #{$mdWidth - 1})";
$tablet_md: "(min-width: #{$tabletWidth}) and (max-width: #{$lgWidth - 1})";
$tablet_sm: "(min-width: #{$tabletWidth}) and (max-width: #{$mdWidth - 1})";

