@import './variables.scss';
@import './mixins.scss';

.tabs {
	&__nav {
		margin: 0 0 30px;
		padding: 0;
		list-style: none;
		display: flex;
		border-bottom: 2px solid rgba($greyColor,0.5);
		@media #{$phone} {
			justify-content: space-between;
		}
		&-control {
			flex-shrink: 0;
			width: 50%;
			margin-bottom: -2px;
			padding: 13px 0;
			font-family: $mainFont;
			font-size: 15px;
			line-height: 1.2;
			font-weight: bold;
			color: $greyColor;
			text-align: left;
			background: none;
			border-radius: 0;
			outline: 0 none;
			border-width: 0 0 2px;
			border-style: solid;
			border-color: transparent;
			user-select: none;
			cursor: pointer;
			transition: 0.2s color ease-in-out, 0.2s border-color ease-in-out;
			text-decoration: none;
			&.active {
				color: $siteColor;
				border-color: $siteColor;
			}
			&.react-tabs__tab--selected {
				color: $siteColor;
				border-color: $siteColor;
			}
			&:hover {
				color: $siteColor;
			}
		}
	}
}