@import "../../assets/mixins";
@import "../../assets/variables";

.radio-wrapper {
  /* General styling for the radio wrapper */
  display: flex;
  align-items: center;

  .radio-control {
    /* Hidden radio input for accessibility */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    
    &:disabled ~ .radio-button-instance {
      opacity: 0.8; /* Disabled state opacity */
      filter: grayscale(1); /* Disabled state grayscale */
      cursor: not-allowed; /* Indicate the disabled state visually */
    }
  
    &:disabled ~ .radio-button-instance .radio-button {
      opacity: 0.8; /* Disabled state opacity */
      filter: grayscale(1); /* Disabled state grayscale */
    }
  }

  .radio-button-instance {
    cursor: pointer;
    /* Common styles for both checked and unchecked states */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 116px; /* Consistent width for both states */
    height: 38px; /* Consistent height for both states */
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, padding 0.2s ease-in-out;

    /* Unchecked state styles */
    padding: 16px 20px; /* Unchecked padding */
    background: #F7F9FF; /* Unchecked background color */
    font-family: 'Manrope', sans-serif; /* Unchecked font */
    color: #334983; /* Unchecked text color */
  }

  /* Checked state styles */
  .radio-control:checked ~ .radio-button-instance {
    padding: 12px 16px; /* Checked padding */
    background: #334983; /* Checked background color */
    font-family: 'Hauora', sans-serif; /* Checked font */
    color: #FFFFFF; /* Checked text color */
    &_error {
      background: #EB5757;
    }
  }

  .radio-button {
    /* Button styles for unchecked state */
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;

    /* Unchecked button dimensions */
    width: 116px;
    height: 38px;
  }

  /* Adjust dimensions for checked state */
  .radio-control:checked ~ .radio-button-instance .radio-button {
    width: 116px; /* Checked button width */
    height: 38px; /* Checked button height */
  }
}
