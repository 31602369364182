@import '../../../assets/variables.scss';
@import '../../../assets/mixins.scss';

.order-verify {
  &__email {
    @include h5();
  }
  &__text {
    @include body();
  }
  &__btn-link {
    @include body();
    text-decoration: underline;
    color: $black1;
    cursor: pointer;
  }
  &__navigation {
    display: flex;
    justify-content: space-between;
  }
  &__field-uppercase {
    text-transform: uppercase;
    &::-webkit-input-placeholder {
      text-transform: none;
    }
    &::-moz-placeholder {
      text-transform: none;
    }
    &:-moz-placeholder {
      text-transform: none;
    }
    &:-ms-input-placeholder {
      text-transform: none;
    }
  }
}